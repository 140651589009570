
import { get, post } from '../config/axios'

// 获取兑换道具
export const getExchangeArrayData = p => get('/prop/exchange/getExchangeArrayData', p);
// 兑换道具
export const exchangeGoods = p => post('/prop/exchange/exchangeGoods', p);
// 绑定/更新支付宝账户
export const payAliAccountBind = p => post('/pay/ali/account/bind', p);
// 实名认证
export const aliRealNameAuth = p => post('/user/basic/aliRealNameAuth', p);
// 主动查询阿里认证状态
export const aliRealNameStatus = p => get('/user/basic/aliRealNameStatus', p);
// 获取用户账户信息以及支付宝账户
export const getUserWalletAndAliPayAccount = p => get('/user/basic/getUserWalletAndAliPayAccount', p);
// 积分消费记录
export const getCashOutBalanceOrder = p => post('/pay/order/getCashOutBalanceOrder', p);
// 提现记录
export const getExchangeLogOfUser = p => get('/prop/exchange/getExchangeLogOfUser', p);
// 获取内部电视墙列表
export const getTvList = p => get('/prop/tvwall/getTvList', p);
// 电视墙规则
export const getTvWallConfig = p => get('/prop/tvwall/getTvWallConfig', p);
// 设置是否上电视墙
export const setUserUpWallConfig = p => post('/prop/tvwall/setUserUpWallConfig', p);

// 获取被禁用的list
export const getForbiddenList = p => post('/user/forbidden/page', p);

// 获取家族扶持时间
export const getFamilyTime = p => get('/chat/familySupport/getSupportTime', p);

//名人堂接口
export const getfamousPerson = p => get('/prop/club/getClubMember', p);

//查询密友
export const getFriendsApi = p => get('/relation/guard/getAmityFriends', p);

//查询关注
export const getAttentionApi = p => get('/relation/fans/getFollowerOfUser', p);

//查询粉丝
export const getFansApi = p => get('/relation/fans/getFansOfUser', p);

//h5支付的接口
export const payment = (p) => post("/pay/buy/recharge", p);


