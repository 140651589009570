<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
//手机端调试,测试环境,需要再打包,平时注释
import VConsole from "vconsole";
if (process.env.VUE_APP_FLAG === "test") {
  let vc = new VConsole();
  console.log(vc);
}

export default {
  data() {
    return {};
  },
  mounted() {},
};
</script>
<style lang="scss">
#app {
  height: 100vh;

  // display: flex;
  // justify-content: center;
  // text-align: center;
  // overflow-x: hidden;

  > div {
    // height: 100vh;
    width: 750px;

    // overflow: hidden;
  }
}

//禁止长按事件
* {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
::-webkit-scrollbar {
  width: 0px; //纵向宽度
  height: 0; //横向高度
}
</style>
