export default {
  name: "grid",
  props: {
    list: {
      default: function _default() {
        return [];
      }
    },
    leftName: {
      default: "name"
    },
    rightName: {
      default: "probability"
    },
    title1: {
      default: "奖品名称"
    },
    title2: {
      default: "中奖概率"
    }
  }
};