import axios from "axios";
import router from "@/router";
// 引入qs模块，用来序列化post类型的数据
// import QS from 'qs';
import store from "@/store";
import envConfig from "./env";
let baseURL = envConfig.baseUrl;
import { Toast } from "vant";
const http = axios.create({
  timeout: 60000,
  baseURL: baseURL,
});

/**
 * 请求前拦截
 * 用于处理需要在请求前的操作
 */
http.interceptors.request.use(
  (config) => {
    if (process.env.NODE_ENV == "development") {
      config.headers["Authorized-Key"] = "71000009:as1516116@token";
      config.headers["Version-Name"] = "2.0.3";
      config.headers["X-Channel"] = "9";
      config.headers["Product-Code"] = "huayu";
    } else {
      const token = store.state.tokenModule.token;
      token && (config.headers["Authorized-Key"] = token);
      const xChannel = store.state.tokenModule.xChannel;
      xChannel && (config.headers["X-Channel"] = xChannel);
      const versionName = store.state.tokenModule.versionName;
      versionName && (config.headers["Version-Name"] = versionName);
      const productCode = store.state.tokenModule.productCode;
      productCode && (config.headers["Product-Code"] = productCode);
    }
    return config;
  },
  (err) => {
    return Promise.reject(err);
  }
);

const to404 = () =>
  process.env.NODE_ENV == "development" || router.replace("/to404");

/**
 * 请求响应拦截
 * 用于处理需要在请求返回后的操作
 */
const all_success_toast_code = /^2331/;//以这个开头的是成功且弹提示的接口
http.interceptors.response.use(
  (res) => {
    //成功返回判断
    if (res.status === 200) {
      let code = res.data.code;
      let successToastcodes = [220711, 221225, 22091006, 22091005,
        220712]; //成功状态码,且需要消息提示弹窗
      if (res.data.code == 401) {
        Toast(res.data.message);
        to404();
        return Promise.resolve(res.data.data);
      } else if (res.data.code == 0) {
        return Promise.resolve(res.data.data);
      } else if (res.data.code !== 0) {
        if (
          code == 90001 ||
          code == 90003 ||
          code == 90004 ||
          code == 90007 ||
          code == 500
        ) {
          Toast(res.data.message);
          to404();
          return;
        } else if (successToastcodes.includes(code) || all_success_toast_code.test(String(code))) {
          Toast(res.data.message);
          return Promise.resolve(res.data.data);
        }
        //判断实名认证的code
        else if (code == 540010) {
          return Promise.reject(res.data);
        }
        Toast(res.data.message);
        return Promise.reject(res.data);
      }
    } else {
      return Promise.reject(res);
    }
  },
  (err) => {
    to404();
    if (err && err.response) {
      switch (err.response.status) {
        case 400:
          err.message = "请求出错";
          break;
        case 401:
          err.message = "授权失败,请重新登录";
          // 清除token
          store.commit("tokenModule/CLEAR_TOKEN");
          break;
        case 403:
          err.message = "拒绝访问";
          break;

        // 404请求不存在
        case 404:
          err.message = "请求错误,未找到该资源";
          break;
        case 500:
          err.message = "服务器端出错";
          break;
      }
    } else {
      // isCloseLoading();
      err.message = "连接服务器失败";
    }
    Toast(err.message);
    return Promise.reject(err);
  }
);

/**
 * get方法，对应get请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function get(url, params) {
  return new Promise((resolve, reject) => {
    http
      .get(url, {
        params: params,
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

/**
 * post方法，对应post请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function post(url, params) {
  return new Promise((resolve, reject) => {
    http
      .post(url, params)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export default http;
