<template>
  <div class="cellBody">
    <div class="item">
      <div class="left">
        <img :src="imgUrl" alt="" />
        <div class="info">
          <div>
            <div class="name">
              <div>{{ cName }}</div>
              <slot name="sIcon"></slot>
            </div>
            <div class="time">
              {{ cTime }}
            </div>
          </div>
        </div>
      </div>
      <div class="right">
        <div class="top">
          {{ cRightTop }}
        </div>
        <div class="btm">
          {{ cRightBtm }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "my-cell",
  props: ["cName", "cTime", "cImg", "cRightTop", "cRightBtm"],
  data() {
    return {
      imgUrl: this.cImg,
    };
  },
};
</script>

<style lang="scss" scoped>
.cellBody {
  border-bottom: 2px solid #f6f6f6;
  padding: 20px 0;
  font-size: 30px;
  font-family: PingFang SC;
  font-weight: 500;
  height: 100px;
  color: #333333;
  div {
  }
  .name {
    font-weight: 700;
    display: flex;
    align-items: center;
  }
  .left img {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    margin-right: 20px;
  }
  .item {
    display: flex;
    justify-content: space-between;
    .left {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .info {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }
    }
    .right {
      //   height: auto;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
    }
  }
}
</style>