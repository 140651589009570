import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import Vue from "vue";
import webViewMixins from "@/mixin/webViewOnMethod";
export default {
  mixins: [webViewMixins],
  name: "nav-bar",
  //custom:自定义执行逻辑不传无影响，1 返回键点击后传给父组件事件,2 返回键点击router返回
  props: ["title", "right_title", "fffImg", "custom"],
  methods: {
    goBack: function goBack() {
      //优先判断自定义逻辑
      if (this.custom == 1) {
        return this.$emit("custom1");
      } else if (this.custom == 2) {
        return this.$router.back();
      }
      //404页面单独设置返回
      if (this.title == "404") {
        console.log("404返回键");
        this.webViewOnMethod(this.device, 0, "");
        return;
      }
      //钻石中心页面单独设置返回
      if (this.device == "ios" && this.$route.path == "/alipayPage") {
        window.location.replace("qingyu.scheme://");
        return;
      }
      if (window.history.length <= 1) {
        this.webViewOnMethod(this.device, 0, "");
      }
      this.$router.back();
    },
    handleRight: function handleRight() {
      this.$emit("handleRight");
    }
  },
  computed: {
    myHeight: function myHeight() {
      if (!this.isWebview()) {
        return "0.88rem";
      }
      var temp = 88 + this.$route.query.statusBarHeight / 2;
      if (this.device == "ios") temp = 88 + (this.$route.query.statusBarHeight - 0);
      Vue.prototype.heightGlobal = this.$route.query.statusBarHeight; //把传来的高度放到原型去
      if (this.device == "ios") {
        var a = temp / 100;
        return "".concat(a, "rem");
      } else {
        var b = temp / 100;
        return "".concat(b, "rem");
      }
    }
  }
};