<template>
  <div class="body">
    <nav-bar title="404" />
    <div class="imgCenter">
      <img src="@/assets/img/else/meixinhao2.png" alt="" />
      <span class="font">找不到信号了o(╥﹏╥)o</span>
    </div>
  </div>
</template>
<script>
export default {
  name: "get404",
  data() {
    return {};
  },
};
</script>
<style lang="scss" scoped>
.body {
  height: 100vh;
  background-color: #fff;
}

.imgCenter {
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  img {
    width: 340px;
    height: 188px;
  }
}

.font {
  font-size: 24px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #999999;
}
</style>
