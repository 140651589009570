import { getCurEnvironment } from "@/utils";
import store from "@/store";
export default {
  data() {
    return {
      device: "",
    };
  },
  created() {
    this.webViewCondition();
  },
  methods: {
    // webview 获取的参数做处理
    webViewCondition() {
      this.device = getCurEnvironment().device;
      let params = this.getRouteParams();
      if (params.versionName) {
        store.commit("tokenModule/SET_VERSIONNAME", params.versionName);
      }
      if (params.xChannel) {
        store.commit("tokenModule/SET_XCHANNEL", params.xChannel);
      }
      if (params.token) {
        store.commit("tokenModule/SET_TOKEN", params.token);
      }
      if (params.productCode) {
        store.commit("tokenModule/SET_PRODUCTCODE", params.productCode);
      }
      if (params.build) {
        store.commit("tokenModule/SET_build", params.build);
      }
    },
    // webView 调用方法
    // ype（跳转类型）目前的参数值：
    // 0返回 1分享 2收益兑换商城 27签到 4我的主页 5语音签名 6编辑个人资料
    // 7我要认证 8家族大厅 9动态（data：0最新 1最热 2关注）
    // 10密友（data：0密友 1粉丝 2关注） 11首页（data：0推荐 1视频速配 2语音速配 3脱单广场）
    //  12发动态 13直播 14消息   15首充大礼包
    // 16 充值送话费 17 绑定手机号  18：购买推荐位 19:个人主页 20:家族主页 21跳转到充值金币弹窗
    // 22 跳转主播申请  23 绑定微信 24 链接分享（分享h5页面） 25 海报分享（分享h5页面） 26  绑定手机号码
    //  27 更多充值  28联系客服 29下载图片 30跳转发动态 31app内部邀请 32内部页面跳转 33新春活动的保存图片  34 关闭webview 35原生杉德支付 36保存图片并且跳动态
    webViewOnMethod(device, type, data) {
      type = parseInt(type);
      if (device === "ios") {
        let obj = {
          type,
          data,
        };
        if (!window.webkit) {
          return false;
        }
        window.webkit.messageHandlers.onWebMethod.postMessage(obj);
      } else if (device === "android") {
        if (data) {
          if (Object.prototype.toString.call(data) === "[object Object]") {
            data = JSON.stringify(data);
          } else {
            data = data.toString();
          }
        }

        if (!window.Android) {
          return false;
        }
        window.Android.onWebMethod(type, data);
      } else {
        console.log("安卓和ios判断都没进去");
      }
    },
    getRouteParams() {
      return this.$route.query;
    },
  },
};
