import Vue from 'vue'
/**
 *  自动扫描子模块,并导入
 */
const requireAll = context => context.keys().map(context);
const components = require.context('./', true, /index\.vue$/);

requireAll(components).forEach((component) => {
    //如果是根目录的index.js,不处理
    if (JSON.stringify(component) === '{}') {
        return;
    }
    /**
     *  抛出错误
     */
    // if (!component.default.name) {
    //     let fileUrl = component.default.__file;
    //     const eroMsg = Error('Component name undefined,The file path is '+fileUrl);
    //     const eroMsg = Error('Component name undefined,The file path is ' + fileUrl);
    //     throw eroMsg;
    // }
    /**
     * 兼容 import export 和 require module.export 两种规范
     */
    Vue.component(component.default.name, component.default || component);
});