import "core-js/modules/es.number.constructor.js";
//showNum展示几条数据，默认3条，需小于滚动数据总条数
//speed滚动间隔时间（单位秒），默认一秒
//moveTime节点移动时间（单位秒），默认一秒
export default {
  name: "rollInfo",
  props: {
    showNum: {
      type: Number || String,
      default: 3
    },
    speed: {
      type: Number || String,
      default: 1
    },
    moveTime: {
      type: Number || String,
      default: 1
    }
  },
  data: function data() {
    return {
      rollBoxHeight: "fit-content",
      //默认是子元素撑起的高度
      rollTimer: null,
      //第一个定时器,控制滚动速度
      innerTimer: null //第二个定时器用于重置move节点的位置，执行时会有轻微顿一下，不明显，解决办法暂无，除非用其他方式重置位置
    };
  },

  methods: {
    //给最外层move节点高度
    getBoxHeight: function getBoxHeight() {
      var dom = document.querySelector(".move").childNodes[0];
      this.rollBoxHeight = dom ? dom.offsetHeight * this.showNum + "px" : "fit-content";
    },
    //滚动事件
    //版本1
    moveEvent: function moveEvent() {
      var _moveNode$childNodes$,
        _this = this;
      var moveNode = document.querySelector(".move"); //move是用来移动的元素
      var itemDomHeight = (_moveNode$childNodes$ = moveNode.childNodes[0]) === null || _moveNode$childNodes$ === void 0 ? void 0 : _moveNode$childNodes$.offsetHeight; //item的高度
      var itemLength = moveNode.childNodes.length; //item个数
      var count = 0; //计数器
      this.rollTimer = setInterval(function () {
        //每次移动的高度
        var H = -itemDomHeight * (count + 1);
        moveNode.style.transform = "translateY(".concat(H, "px)"); //每次移动item高度
        moveNode.style.transition = "transform ".concat(_this.moveTime, "s");
        count++;
        //滚动到底时逻辑
        if (count == itemLength - _this.showNum) {
          _this.innerTimer = setTimeout(function () {
            clearTimeout(_this.innerTimer);
            //更换dom位置法（一直重绘回流）
            var doms = moveNode.childNodes;
            for (var i = 0; i < _this.showNum - 1; i++) {
              var removeDom = doms[0];
              moveNode.appendChild(removeDom);
            }
            //重置位置
            count = 0;
            moveNode.style.transform = "translateY(0px)";
            moveNode.style.transition = "transform 0s";
          }, _this.speed * 1000 - 50);
        }
      }, this.speed * 1000);
    }
  },
  mounted: function mounted() {
    this.getBoxHeight();
    this.moveEvent();
  },
  beforeDestroy: function beforeDestroy() {
    clearInterval(this.rollTimer);
    clearInterval(this.innerTimer);
  }
};