var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "cell-body",
    on: {
      "click": function click($event) {
        return _vm.$emit('click');
      }
    }
  }, [_vm.$slots.cellL ? _vm._t("cellL") : _c('div', {
    staticClass: "cell-left"
  }, [_vm.$slots.rank ? _vm._t("rank") : [_vm.rank ? _c('div', {
    staticClass: "cell-rank"
  }, [_vm._v(_vm._s(_vm.rank))]) : _vm._e()], _vm.$slots.avatar ? _vm._t("avatar") : _c('div', {
    staticClass: "cell-avatar"
  }, [_vm.avatar[0] ? _c('img', {
    attrs: {
      "src": _vm.avatar[0],
      "alt": ""
    }
  }) : _vm._e(), _vm.avatar[1] ? _c('img', {
    attrs: {
      "src": _vm.avatar[1],
      "alt": ""
    }
  }) : _vm._e()]), _c('div', {
    staticClass: "cell-user"
  }, [_vm.$slots.user ? _vm._t("user") : [_vm.user[0] ? _c('div', {
    staticClass: "user-top"
  }, [_vm._v(_vm._s(_vm.user[0]))]) : _vm._e(), _vm.user[1] ? _c('div', {
    staticClass: "user-btm"
  }, [_vm._v(_vm._s(_vm.user[1]))]) : _vm._e()]], 2)], 2), _vm.$slots.cellR ? _vm._t("cellR") : _c('div', {
    staticClass: "cell-right"
  }, [_vm.right[0] ? _c('div', {
    staticClass: "right-top"
  }, [_vm._v(_vm._s(_vm.right[0]))]) : _vm._e(), _vm.right[1] ? _c('div', {
    staticClass: "right-btm"
  }, [_vm._v(_vm._s(_vm.right[1]))]) : _vm._e()])], 2);
};
var staticRenderFns = [];
export { render, staticRenderFns };