var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "c-festivalBtmBody"
  }, [_vm.$slots.hasOwnProperty('default') ? _vm._t("default") : [_c('div', {
    staticClass: "c-flexBetween"
  }, [_vm.showUser ? [_c('div', {
    staticClass: "c-btmLeft"
  }, [_vm.$slots.hasOwnProperty('left') ? _vm._t("left") : [_vm.getUserInfo.rankNum ? _c('h4', {
    staticClass: "rank-num"
  }, [_vm._v(" " + _vm._s(_vm.getUserInfo.rankNum) + " ")]) : _vm._e(), _c('img', {
    staticClass: "c-avatarL",
    attrs: {
      "src": _vm.getUserInfo.avatarL,
      "alt": ""
    }
  }), _vm.getUserInfo.avatarR ? _c('img', {
    staticClass: "c-avatarR",
    attrs: {
      "src": _vm.getUserInfo.avatarR,
      "alt": ""
    }
  }) : _vm._e(), _c('div', {
    staticClass: "c-userInfo"
  }, [_c('div', [_vm._v(_vm._s(_vm.getUserInfo.userL))]), _vm.getUserInfo.userR != undefined ? _c('div', [_vm._v(" " + _vm._s(_vm.getUserInfo.userR) + " ")]) : _vm._e()])]], 2), _c('div', {
    staticClass: "c-btmRight"
  }, [_vm.$slots.hasOwnProperty('right') ? _vm._t("right") : [_vm.getUserInfo.rightTitle ? _c('div', {
    staticClass: "c-scoreTitle"
  }, [_vm._v(" " + _vm._s(_vm.getUserInfo.rightTitle) + " ")]) : _vm._e(), _c('div', {
    staticClass: "c-score"
  }, [_vm._v(_vm._s(_vm.getUserInfo.score))])]], 2)] : [_c('p', {
    staticClass: "c-noRank",
    domProps: {
      "innerHTML": _vm._s(_vm.noUserInfo.text)
    }
  }), _c('div', {
    staticClass: "c-noRankBtn",
    on: {
      "click": function click($event) {
        return _vm.$emit('rightBtnEvent');
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.noUserInfo.btnText) + " ")])]], 2)], _vm._t("add")], 2);
};
var staticRenderFns = [];
export { render, staticRenderFns };