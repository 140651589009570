<template>
  <div class="cell-body">
    <div class="left-box">
      <slot name="left"></slot>
    </div>
    <div class="right-box">
      <slot name="right"></slot>
    </div>
  </div>
</template>
<script>
export default {
  name: "BetweenCell",
  data() {
    return {};
  },
};
</script>
<style lang='scss' scoped>
.cell-body {
  box-sizing: border-box;
  padding: 0 30px;
  width: 100%;
  height: 114px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .left-box,
  .right-box {
    display: flex;
    align-items: center;
  }
  .right-box {
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
  }
}
</style>