import "core-js/modules/es.error.cause.js";
import "core-js/modules/es.error.to-string.js";
/**
 * 在移动端网页使用效果感觉不好
 */
//dom：querySelector的参数
//bgColor：遮罩的颜色
//speed：速度，单位s
//isAnimation：是否需要本组件的旋转动画
export default {
  name: "MeLoading",
  props: {
    dom: {
      default: "body"
    },
    bgColor: {
      default: "rgba(255,242,226, 0.5)"
    },
    speed: {
      default: 1
    },
    isAnimation: {
      default: true
    }
  },
  data: function data() {
    return {
      show: false
    };
  },
  methods: {
    //插入到指定DOM
    insertDom: function insertDom() {
      this.isSingle([this.dom, ".cy-loading"]);
      var parentNode = document.querySelector(this.dom);
      var currentNode = document.querySelector(".cy-loading");
      if (!parentNode || !currentNode) return;
      //设置相对定位
      if (window.getComputedStyle(parentNode).position !== "relative") {
        parentNode.style.position = "relative";
      }
      //插入元素
      parentNode.appendChild(document.querySelector(".cy-loading"));
    },
    //设置样式
    setDomStyle: function setDomStyle() {
      this.isSingle(".cy-loading");
      var currentNode = document.querySelector(".cy-loading");
      var centerContainer = document.querySelector(".cy-loading .center-container");
      if (!currentNode || !centerContainer) return;
      //设置遮罩背景颜色
      currentNode.style.backgroundColor = this.bgColor;
      //动画相关
      if (this.isAnimation) {
        centerContainer.style.setProperty("--animation-duration", this.speed + "s");
      } else {
        centerContainer.style.animation = "none";
      }
    },
    //判断是否是单例
    isSingle: function isSingle(className) {
      var errString = "当前dom树存在多个loading组件,只能存在一个";
      if (Array.isArray(className)) {
        for (var i = 0; i < className.length; i++) {
          if (document.querySelectorAll(className[i]).length > 1) {
            throw Error(errString);
          }
        }
      } else {
        if (document.querySelectorAll(className).length > 1) {
          throw Error(errString);
        }
      }
    },
    open: function open() {
      this.show = true;
    },
    close: function close() {
      this.show = false;
    }
  },
  mounted: function mounted() {
    this.setDomStyle();
    this.insertDom();
  }
};