var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "gridBody"
  }, [_c('div', {
    staticClass: "grid"
  }, [_c('div', {
    staticClass: "gridItem titleItem"
  }, [_c('div', {
    staticClass: "itenLeft"
  }, [_vm._v(_vm._s(_vm.title1))]), _c('div', {
    staticClass: "itenRight"
  }, [_vm._v(_vm._s(_vm.title2))])]), _vm._l(_vm.list, function (item, i) {
    return _c('div', {
      key: i,
      staticClass: "gridItem listItem"
    }, [_c('div', {
      staticClass: "itenLeft"
    }, [_vm._v(_vm._s(item[_vm.leftName]))]), _c('div', {
      staticClass: "itenRight"
    }, [_vm._v(_vm._s(item[_vm.rightName]))])]);
  })], 2)]);
};
var staticRenderFns = [];
export { render, staticRenderFns };