var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "scrollBox"
  }, [_c('van-list', {
    ref: "vanList",
    attrs: {
      "offset": "20",
      "loading-text": "加载中...",
      "finished": _vm.finished,
      "finished-text": _vm.finishedText
    },
    on: {
      "load": _vm.onLoad
    },
    model: {
      value: _vm.loading,
      callback: function callback($$v) {
        _vm.loading = $$v;
      },
      expression: "loading"
    }
  }, [_vm._t("titleRow"), _vm._l(_vm.list, function (item, i) {
    return _c('div', {
      key: i,
      staticClass: "list-item"
    }, [_vm._t("default", null, {
      "data": {
        item: item,
        i: i
      }
    })], 2);
  })], 2)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };