import _toConsumableArray from "C:/Users/Administrator/Desktop/huayu-h5/node_modules/_@babel_runtime@7.20.7@@babel/runtime/helpers/esm/toConsumableArray.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.array.concat.js";
import Vue from "vue";
import VueRouter from "vue-router";
import { festivalList } from "./festival";
import { formerlyRouter } from "./formerlyRouter";
var userAgreement = function userAgreement() {
  return import("../pages/userAgreement");
};
var privacyPolicy = function privacyPolicy() {
  return import("../pages/privacyPolicy");
};
var integralCenter = function integralCenter() {
  return import("../pages/integralCenter");
};
var alipayBind = function alipayBind() {
  return import("../pages/alipayBind");
};
var exchangeCenter = function exchangeCenter() {
  return import("../pages/exchangeCenter");
};
var realName = function realName() {
  return import("../pages/realName");
};
var forRecord = function forRecord() {
  return import("../pages/forRecord");
};
var tvWall = function tvWall() {
  return import("../pages/tvWall");
};
//抽奖车
var CashWithdrawals = function CashWithdrawals() {
  return import("../pages/CashWithdrawals");
};

//分享页面
var share = function share() {
  return import("../pages/share");
};
var UlinkShare = function UlinkShare() {
  return import("../pages/UlinkShare");
}; //友盟的分享

// 任务中心
var taskCenter = function taskCenter() {
  return import("../pages/taskCenter");
};
// 礼物周星榜
var giftweek = function giftweek() {
  return import("../pages/giftweek");
};
// 摩天轮
// const ferrisWheel = () => import("../pages/anim/ferrisWheel.vue");

Vue.use(VueRouter);
var routes = [{
  path: "/userAgreement",
  name: "userAgreement",
  meta: {
    name: "用户协议"
  },
  component: userAgreement
}, {
  path: "/privacyPolicy",
  name: "privacyPolicy",
  meta: {
    name: "隐私政策"
  },
  component: privacyPolicy
}, {
  path: "/createFamilyAgreement",
  name: "createFamilyAgreement",
  meta: {
    name: "家族管理协议"
  },
  component: function component() {
    return import("@/pages/createFamilyAgreement");
  }
}, {
  path: "/integralCenter",
  name: "integralCenter",
  meta: {
    name: "积分中心"
  },
  component: integralCenter
}, {
  path: "/alipayBind",
  name: "alipayBind",
  meta: {
    name: "绑定/换绑支付宝"
  },
  component: alipayBind
}, {
  path: "/exchangeCenter",
  name: "exchangeCenter",
  meta: {
    name: "兑换中心"
  },
  component: exchangeCenter
}, {
  path: "/realName",
  name: "realName",
  meta: {
    name: "实名认证"
  },
  component: realName
}, {
  path: "/forRecord",
  name: "forRecord",
  meta: {
    name: "兑换记录"
  },
  component: forRecord
}, {
  path: "/tvWall",
  name: "tvWall",
  meta: {
    name: "电视墙"
  },
  component: tvWall
}, {
  path: "/share",
  name: "share",
  meta: {
    name: "分享页面"
  },
  component: share
}, {
  path: "/UlinkShare",
  name: "UlinkShare",
  meta: {
    name: "友盟分享h5落地页"
  },
  component: UlinkShare
}, {
  path: "/platformRanking",
  name: "platformRanking",
  meta: {
    name: "平台排行榜"
  },
  component: function component() {
    return import("../pages/platformRanking");
  }
}, {
  path: "/taskCenter",
  name: "taskCenter",
  meta: {
    name: "任务中心"
  },
  component: taskCenter
}, {
  path: "/giftweek",
  name: "giftweek",
  meta: {
    name: "礼物周星榜"
  },
  component: giftweek
}, {
  path: "/ferrisWheel",
  name: "ferrisWheel",
  meta: {
    name: "幸福摩天轮"
  },
  component: function component() {
    return import("../pages/ferrisWheel");
  }
}, {
  path: "/snatch",
  name: "snatch",
  meta: {
    name: "海底夺宝"
  },
  component: function component() {
    return import("../pages/snatch");
  }
},
// {
//   path: "/eggMachine",
//   name: "eggMachine",
//   meta: {
//     name: "扭蛋机",
//   },
//   component: () => import("../pages/eggMachine"),
// },
{
  path: "/moneyAgreement",
  name: "moneyAgreement",
  meta: {
    name: "充值协议"
  },
  component: function component() {
    return import("../pages/moneyAgreement");
  }
}, {
  path: "/alipayPage",
  name: "alipayPage",
  meta: {
    name: "充值"
  },
  component: function component() {
    return import("../pages/alipayPage");
  }
}, {
  path: "/payResult",
  name: "payResult",
  meta: {
    name: "支付结果页面"
  },
  component: function component() {
    return import("../pages/payResult");
  }
}, {
  path: "/auctionRule",
  name: "auctionRule",
  meta: {
    name: "拍卖规则"
  },
  component: function component() {
    return import("../pages/auctionRule");
  }
}, {
  path: "/violations",
  name: "violations",
  meta: {
    name: "违规处罚公示"
  },
  component: function component() {
    return import("../pages/violations");
  }
}, {
  path: "/standard",
  name: "standard",
  meta: {
    name: "用户行为规范"
  },
  component: function component() {
    return import("../pages/standard");
  }
}, {
  path: "/chatAgreement",
  name: "chatAgreement",
  meta: {
    name: "语聊房管理协议"
  },
  component: function component() {
    return import("../pages/chatAgreement");
  }
}, {
  path: "/platformAgreement",
  name: "platformAgreement",
  meta: {
    name: "平台管理协议"
  },
  component: function component() {
    return import("../pages/platformAgreement");
  }
}, {
  path: "/introduce",
  name: "introduce",
  meta: {
    name: "礼物介绍"
  },
  component: function component() {
    return import("../pages/introduce");
  }
}, {
  path: "/blindBox",
  name: "blindBox",
  meta: {
    name: "盲盒"
  },
  component: function component() {
    return import("../pages/blindBox");
  }
}, {
  path: "/payBag",
  name: "payBag",
  meta: {
    name: "充值礼包"
  },
  component: function component() {
    return import("../pages/payBag");
  }
}, {
  path: "/familyPoster4",
  name: "familyPoster4",
  meta: {
    name: "家族扶持v4"
  },
  component: function component() {
    return import("../pages/familyPoster4");
  }
}, {
  path: "/familyPoster5",
  name: "familyPoster5",
  meta: {
    name: "家族扶持v5"
  },
  component: function component() {
    return import("../pages/familyPoster5");
  }
}, {
  path: "/constellationStory",
  name: "constellationStory",
  meta: {
    name: "星座物语"
  },
  component: function component() {
    return import("../pages/constellationStory");
  }
}, {
  path: "/playAgreement",
  name: "playAgreement",
  meta: {
    name: "提现协议"
  },
  component: function component() {
    return import("../pages/playAgreement");
  }
}, {
  path: "/familyList",
  name: "familyList",
  meta: {
    name: "家族风云榜"
  },
  component: function component() {
    return import("../pages/familyList");
  }
}, {
  path: "/antiFraud",
  name: "antiFraud",
  meta: {
    name: "防骗指南"
  },
  component: function component() {
    return import("../pages/antiFraud");
  }
}, {
  path: "/celebrity",
  name: "celebrity",
  meta: {
    name: "名人堂"
  },
  component: function component() {
    return import("../pages/celebrity");
  }
}, {
  path: "/celebrityDetail",
  name: "celebrityDetail",
  meta: {
    name: "名人堂说明"
  },
  component: function component() {
    return import("../pages/celebrity/detail.vue");
  }
}, {
  path: "/mentalIntroduction",
  name: "mentalIntroduction",
  meta: {
    name: "心动模式说明"
  },
  component: function component() {
    return import("../pages/mentalIntroduction");
  }
}, {
  path: "/jsapiPage",
  name: "jsapiPage",
  meta: {
    name: "jsapi支付桥接页面"
  },
  component: function component() {
    return import("../pages/jsapiPage");
  }
}, {
  path: "/gemPay",
  name: "gemPay",
  meta: {
    name: "jsapi支付钻石充值页面"
  },
  component: function component() {
    return import("../pages/jsapiPage/gemPay.vue");
  }
}, {
  path: "/certificate",
  name: "certificate",
  meta: {
    name: "甜蜜证书"
  },
  component: function component() {
    return import("../pages/certificate");
  }
}, {
  path: "/weddingIntroduce",
  name: "weddingIntroduce",
  meta: {
    name: "婚礼介绍"
  },
  component: function component() {
    return import("../pages/weddingIntroduce");
  }
}, {
  path: "/ktv",
  name: "ktv",
  meta: {
    name: "ktv介绍"
  },
  component: function component() {
    return import("../pages/ktv");
  }
}, {
  path: "/specialNumber",
  name: "specialNumber",
  meta: {
    name: "个人靓号"
  },
  component: function component() {
    return import("../pages/specialNumber");
  }
}, {
  path: "/to404",
  name: "to404",
  meta: {
    name: "404"
  },
  component: function component() {
    return import("../pages/to404");
  }
}, {
  path: "/test",
  name: "test",
  meta: {
    name: "测试页面"
  },
  component: function component() {
    return import("../pages/test");
  }
}, {
  path: "/forbidDeal",
  name: "forbidDeal",
  meta: {
    name: "严禁私下交易"
  },
  component: function component() {
    return import("@/pages/forbidDeal");
  }
}, {
  path: "/redBagAgreement",
  name: "redBagAgreement",
  meta: {
    name: "红包协议"
  },
  component: function component() {
    return import("@/pages/redBagAgreement");
  }
}, {
  path: "/familyUp",
  name: "familyUp",
  meta: {
    name: "家族成长计划"
  },
  component: function component() {
    return import("@/pages/familyUp");
  }
}].concat(_toConsumableArray(festivalList));
var router = new VueRouter({
  routes: routes
});
export default router;