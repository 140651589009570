<template>
    <div>
        <van-popup @opened="$emit('opened')" :lock-scroll="lock_scroll" :close-on-click-overlay="close_on_click_overlay"
            :overlay="false" closeable round v-model="show" position="bottom">
            <div class="c-content">
                <h5>分享</h5>
                <div class="platforms">
                    <div @click="$emit('clickFn', i)" v-for="(item, i) in list" :key="i" class="item">
                        <img :src="item.url" alt="">
                        <span>{{ item.name }}</span>
                    </div>
                </div>
                <div @click="$emit('clickFn', list.length)" class="saveBtn">
                    <img src="@/assets/img/else/baocun.png" alt="">
                    保存图片到本地
                </div>
            </div>
        </van-popup>
    </div>
</template>
<script>
import wx from '@/assets/img/else/weixin.png'
import wxF from '@/assets/img/else/pengyouquan.png'
import qq from '@/assets/img/else/qq.png'
import qqF from '@/assets/img/else/qqkongjian.png'
export default {
    name: "btmShare",
    props: {
        shareShow: {},
        lock_scroll: { default: true },
        close_on_click_overlay: { default: true },
    },
    data() {
        return {
            list: [
                {
                    url: wx,
                    name: '微信'
                },
                {
                    url: wxF,
                    name: '朋友圈'
                },
                {
                    url: qq,
                    name: 'QQ'
                },
                {
                    url: qqF,
                    name: 'QQ空间'
                },
            ]
        };
    },
    computed: {
        show: {
            get() { return this.shareShow },
            set(v) { this.$emit('set_shareShow', v) }
        }
    }
};
</script>
<style lang='scss' scoped>
.c-content {
    padding: 40px 20px 60px;
    color: #333333;
    font-size: 24px;

    h5 {
        font-size: 32px;
        text-align: center;
    }

    .platforms {
        margin: 30px 20px;
        display: flex;
        justify-content: space-around;

        .item {
            display: flex;
            align-items: center;
            flex-direction: column;
        }

        img {
            margin-bottom: 6px;
            height: 100px;
        }
    }

    .saveBtn {
        margin: 0 auto;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 600px;
        height: 96px;
        background: #FFFFFF;
        border-radius: 48px;
        border: 2px solid #C1C1C1;

        img {
            height: 30px;
            margin-right: 10px;
        }
    }
}
</style>