<template>
  <div class="nodata">
    <img src="@/assets/img/nodata.png" alt="" />
    <div v-if="title" class="text">{{ title }}</div>
    <div v-else class="text">这里空空如也(｡•́︿•̀｡)</div>
  </div>
</template>

<script>
export default {
  name: "no-data",
  props: ["title"],
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/base.scss";
.nodata {
  width: 100%;
  max-width: countRem(750);
  display: flex;
  flex-direction: column;
  align-items: center;
  img {
    margin-top: countRem(90);
    width: countRem(340);
    height: countRem(188);
    margin-bottom: countRem(12);
  }
  .text {
    font-size: countRem(24);
    font-family: PingFang SC;
    font-weight: 400;
    color: #999999;
  }
}
</style>