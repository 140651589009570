var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    style: {
      height: "".concat(_vm.myHeight)
    },
    attrs: {
      "id": "navbarCont"
    }
  }, [_c('div', {
    staticClass: "navbar"
  }, [_c('div', {
    staticClass: "back",
    on: {
      "click": function click($event) {
        return _vm.goBack();
      }
    }
  }, [this.fffImg ? _c('img', {
    attrs: {
      "src": require("@/assets/img/CashWithdrawalsImg/back2.png"),
      "alt": ""
    }
  }) : _c('img', {
    attrs: {
      "src": require("@/assets/img/back_black.png"),
      "alt": ""
    }
  })]), _vm.title ? _c('div', {
    staticClass: "title"
  }, [_vm._v(" " + _vm._s(_vm.title) + " ")]) : _vm._e(), _vm.right_title ? _c('div', {
    staticClass: "right",
    on: {
      "click": _vm.handleRight
    }
  }, [_vm._v(" " + _vm._s(_vm.right_title) + " ")]) : _vm._e()])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };