const tokenModule = {
  namespaced: true,
  state: {
    build: localStorage.getItem("build") || "",
    token: localStorage.getItem("Authorized-Key") || "",
    xChannel: localStorage.getItem("X-Channel") || "",
    versionName: localStorage.getItem("Version-Name") || "",
    productCode: localStorage.getItem("Product-Code") || "",
  },
  mutations: {
    SET_build(state, build) {
      state.build = build;
      localStorage.setItem("build", build);
    },
    // 保存token
    SET_TOKEN(state, token) {
      state.token = token;
      localStorage.setItem("Authorized-Key", token);
    },
    // 移除token
    CLEAR_TOKEN(state, token) {
      state.token = "";
      localStorage.removeItem("Authorized-Key");
    },
    SET_XCHANNEL(state, xChannel) {
      state.xChannel = xChannel;
      localStorage.setItem("X-Channel", xChannel);
    },
    SET_VERSIONNAME(state, versionName) {
      state.versionName = versionName;
      localStorage.setItem("Version-Name", versionName);
    },
    SET_PRODUCTCODE(state, productCode) {
      state.productCode = productCode;
      localStorage.setItem("Product-Code", productCode);
    },
  },
  actions: {},
};
export default tokenModule;
