import Vue from "vue";
import {
  Picker,
  Toast,
  Popup,
  Field,
  Dialog,
  Loading,
  List,
  PullRefresh,
  Swipe,
  SwipeItem,
  ImagePreview,
  Sticky,
  Tab,
  Tabs,
  Cell,
  CellGroup,
  Button,
  ShareSheet,
  NavBar,
  Search,
  CountDown,
  NoticeBar,
  Stepper,
  Lazyload,
  Checkbox,
  CheckboxGroup,
  Switch,
  Icon,
  Image as VanImage,
} from "vant";
Vue.use(Button);
Vue.use(NavBar);
Vue.use(CountDown);
Vue.use(NoticeBar);
Vue.use(Switch);
Vue.use(Search);
Vue.use(Stepper);
Vue.use(Checkbox);
Vue.use(CheckboxGroup);

Vue.use(Picker);
Vue.use(Toast);
Vue.use(Dialog);
Vue.use(Popup);
Vue.use(Field);
Vue.use(Loading);
Vue.use(List);
Vue.use(ShareSheet);
Vue.use(PullRefresh);
Vue.use(Swipe);
Vue.use(SwipeItem);
Vue.use(ImagePreview);
Vue.use(Sticky);
Vue.use(Tab);
Vue.use(Tabs);
Vue.use(Cell);
Vue.use(CellGroup);
Vue.use(VanImage);
Vue.use(Icon);
Vue.use(Lazyload, {
  lazyComponent: true,
});
