var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', [_c('div', {
    staticClass: "rollBox",
    style: {
      height: _vm.rollBoxHeight
    }
  }, [_c('div', {
    staticClass: "move"
  }, [_vm._t("default")], 2)])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };