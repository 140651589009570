//中秋数据

export default {
    namespaced: true,
    state: {
        ruleShow: false,
        awardShow: false,
        recordShow: false,
    },
    mutations: {
        //obj={key:对应state的变量名，value:修改的值}
        setState(state, obj) {
            state[obj.key] = obj.value
        },
    },
}