import _typeof from "C:/Users/Administrator/Desktop/huayu-h5/node_modules/_@babel_runtime@7.20.7@@babel/runtime/helpers/esm/typeof.js";
import "core-js/modules/es.object.keys.js";
//titleList:string
export default {
  name: "AwardTable",
  props: {
    titleList: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    list: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    firstColumnList: {
      type: Array,
      default: function _default() {
        return [];
      }
    }
  },
  methods: {
    //是否显示斜线
    showLine: function showLine(item) {
      if (item === undefined || item === null) return false;
      if (_typeof(item) !== "object") return true;
      if (Object.keys(item).length == 0) return false;
      return true;
    },
    //是否小字体文本显示
    smallTextFn: function smallTextFn(str) {
      if (typeof str === "string" && str.length > 10) return true;
    }
  }
};