var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "c-award-tableBody"
  }, [_c('table', {
    ref: "table",
    staticClass: "c-table",
    attrs: {
      "cellspacing": "0"
    }
  }, [_c('tr', {
    staticClass: "first-row"
  }, [_vm.$slots.hasOwnProperty('title') ? _vm._t("title") : _vm._l(_vm.titleList, function (item, i) {
    return _c('td', {
      key: i
    }, [_vm._v(_vm._s(item))]);
  })], 2), _vm._l(_vm.list, function (listItem, i) {
    return _c('tr', {
      key: i,
      class: ["row".concat(i + 1)]
    }, [_vm.$slots.hasOwnProperty('list') ? _vm._t("list") : [_c('td', {
      class: ['first-column', {
        gemSty: _vm.firstColumnList[i].type == 6
      }]
    }, [_c('img', {
      attrs: {
        "src": _vm.firstColumnList[i].src,
        "alt": ""
      }
    }), _c('div', [_vm._v(_vm._s(_vm.firstColumnList[i].name))])]), _vm._l(listItem, function (val, k) {
      return _c('td', {
        key: k,
        class: {
          'small-text': _vm.smallTextFn(val.rewardName)
        }
      }, [val.rewardType == 6 ? [_vm._v(" " + _vm._s(val.rewardQty) + " ")] : _vm.showLine(val) ? [_vm._t("default", null, {
        "val": val
      })] : _c('span', {
        staticClass: "diagonalLine"
      })], 2);
    })]], 2);
  })], 2)]);
};
var staticRenderFns = [];
export { render, staticRenderFns };