var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _vm.ifShow ? _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.visible,
      expression: "visible"
    }]
  }, [_vm._t("default")], 2) : _vm._e();
};
var staticRenderFns = [];
export { render, staticRenderFns };