<template>
  <div>
    <van-popup position="bottom" v-model="show" get-container="body" :class="className">
      <div class="f_content">
        <div class="f_title">
          <img @click="show = false" src="@/assets/img/back_black.png" alt="" />
          {{ f_title }}
        </div>
        <!-- tab -->
        <div class="f_tab">
          <div @click="tabChange(i)" v-for="(item, i) in ['密友', '关注', '粉丝']" :key="i"
            :class="{ f_tab_item: true, f_act_item: i == f_act }">
            <span>{{ item }}</span>
            <div v-show="i == f_act" class="itemLine"></div>
          </div>
        </div>
        <!-- 好友list -->
        <div class="f_list">
          <van-list v-model="loading" :finished="finished" @load="onLoad">
            <div @click="checkChange(i)" v-for="(item, i) in list" :key="i" class="f_list_item">
              <div class="left">
                <img :src="item.user.avatar.url" alt="" />
                <div class="name">{{ item.user.name }}</div>
              </div>
              <van-checkbox :checked-color="checkedColor" v-model="checkeds[i]"></van-checkbox>
            </div>
          </van-list>
        </div>
        <div @click="getUser" class="f_btn">确定</div>
      </div>
    </van-popup>
  </div>
</template>
<script>
import { getFriendsApi, getAttentionApi, getFansApi } from "@/api";
export default {
  name: "getFriends",
  props: {
    f_title: {},
    f_show: {},
    f_isRadio: {
      default: true,
    },
    className:String,
    checkedColor: {
      default: '#ee0a24'
    }
  },
  data() {
    return {
      f_act: 0,
      pageNo: 1,
      loading: false,
      finished: false,
      list: [],
      checkeds: [],
    };
  },
  methods: {
    getUser() {
      let arr = [];
      this.checkeds.forEach((item, i) => {
        if (item && this.list[i]) {
          arr.push({
            name: this.list[i].user.name,
            id: this.list[i].user.userId,
            avatar: this.list[i].user.avatar.url,
          });
        }
      });
      this.$emit("close_f", arr.length && arr);
    },
    //tab切换
    tabChange(n) {
      this.pageNo = 1;
      this.f_act = n;
      this.onLoad(true);
    },
    //复选框事件
    checkChange(i) {
      if (this.f_isRadio) {
        this.checkeds.forEach((item, index) => {
          this.$set(this.checkeds, index, false);
        });
      }
      this.$set(this.checkeds, i, !this.checkeds[i]);
    },
    async onLoad(reset) {
      if (reset == true) {
        this.list = [];
      }
      let fun = null;
      if (this.f_act == 0) fun = getFriendsApi;
      else if (this.f_act == 1) fun = getAttentionApi;
      else if (this.f_act == 2) fun = getFansApi;
      let data = await fun({
        reqTime: parseInt(+new Date() / 1000),
        "pageInfo.pageNo": this.pageNo,
        "pageInfo.pageSize": 20,
      });
      this.loading = false;
      this.pageNo++;
      if (this.list.length == 0) {
        this.list = data.infoList;
      } else if (this.list.length < data.pageInfo.total) {
        this.list.push(...data.infoList);
      }
      if (this.list.length >= data.pageInfo.total) {
        this.finished = true;
      }
      this.checkeds = new Array(this.list.length).fill(false);
    },
  },
  created() {
    // this.onLoad()
  },
  computed: {
    show: {
      get() {
        return this.f_show;
      },
      set(v) {
        if (v === this.show) return;
        return this.$emit("close_f");
      },
    },
  },
};
</script>
<style lang='scss' scoped>
.f_content {
  position: relative;
  width: 100vw;
  background-color: #fff;
  font-size: 32px;
  font-weight: 400;
  color: #333333;
  // padding-bottom: 100px;

  .f_title {
    position: relative;
    height: 88px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;

    img {
      position: absolute;
      left: 30px;
      top: 50%;
      transform: translateY(-50%);
      width: 22px;
      height: 38px;
    }
  }

  .f_btn {
    position: fixed;
    bottom: 50px;
    left: 50%;
    transform: translateX(-50%);
    width: 600px;
    height: 90px;
    background: linear-gradient(270deg, #e450f0 0%, #9450f0 100%);
    border-radius: 60px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.f_tab {
  margin: 10px 50px;
  display: flex;

  .f_tab_item {
    margin-right: 100px;
    color: #999999;
    display: flex;
    flex-direction: column;
    align-items: center;

    .itemLine {
      width: 30px;
      height: 6px;
      background-color: #9450f0;
      margin-top: 6px;
      border-radius: 6px;
    }
  }

  .f_act_item {
    font-weight: bold;
    color: #000;
  }
}

.f_list {
  height: 500px;
  margin: 0 28px;
  overflow-y: scroll;
  padding-bottom: 150px;

  &::-webkit-scrollbar {
    display: none;
  }

  .f_list_item {
    display: flex;
    justify-content: space-between;
    height: 160px;
    font-size: 30px;

    .left {
      display: flex;
      align-items: center;
    }

    img {
      width: 100px;
      height: 100px;
      border-radius: 50%;
      margin-right: 22px;
      background-color: #000;
    }
  }
}
</style>