// 设备及使用环境
export const getCurEnvironment = () => {
    var ua = navigator.userAgent.toLowerCase();
    // 设备
    let device = 'android';
    // 浏览器
    let browser = 'common';
    if (/android|adr/gi.test(ua)) {
        // 安卓
        device = 'android'
    } else if (/\(i[^;]+;( U;)? CPU.+Mac OS X/gi.test(ua)) {
        //苹果
        device = 'ios'
    } else if (/iPad/gi.test(ua)) {
        //ipad
        device = 'ipad'
    }
    if (ua.match(/MicroMessenger/i) == "micromessenger") {
        browser = 'wx';
        return {
            device,
            browser
        }
    }
    if (ua.indexOf(' qq') > -1 && ua.indexOf('mqqbrowser') < 0) {
        browser = 'qq';
        return {
            device,
            browser
        }
    }
    window.$device = device
    return {
        device,
        browser
    }
};

export const isNullStr = (str) => {
    if (!str) {
        return true;
    }
    // 兼容旧浏览器
    if (str.trim) {
        str = str.trim();
    } else {
        str = str.toString().replace(/(^\s*)|(\s*$)/g, "");
    }
    if (str === '' || str === 'null' || str === 'undefined') {
        return true;
    }
    return false;
};
// export default {
//     getCurEnvironment,
//     isNullStr
// }