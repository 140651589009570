export default {
  name: "Lazy",
  props: ["visible"],
  data: function data() {
    return {
      ifShow: false,
      watch_instance: function watch_instance() {}
    };
  },
  created: function created() {
    var _this = this;
    this.watch_instance = this.$watch("visible", {
      handler: function handler(v) {
        if (v) {
          _this.ifShow = true;
          _this.watch_instance();
        }
      },
      immediate: true
    });
  }
};