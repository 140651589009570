//夺宝抽奖数据
const dragonBoatFestival = {
    namespaced: true,
    state: {
        endTime:0
    },
    mutations: {
        setendTime(state,value){
            state.endTime=value
        },
        
    },

}
export default dragonBoatFestival