//夺宝抽奖数据
const seabedCombat = {
    namespaced: true,
    state: {
        comInfo:{pool:{}},//奖池初始化信息
        drawResult:{},//抽奖结果
        resultShow:false,//抽奖结果弹窗
    },
    mutations: {
        setcomInfo(state,value){
            state.comInfo=value
        },
        setdrawResult(state,value){
            state.drawResult=value
        },
        setresultShow(state,value){
            state.resultShow=value
        },
    },

}
export default seabedCombat