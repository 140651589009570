/**
 * 配置编译环境和测试环境和线上环境之间的切换
 *
 * baseUrl: 域名地址
 *
 */

let baseUrl;
// 开发环境
// const devUrl = 'https://qingyu-api-test.starcultural.com';
const devUrl = "https://qingyu-api-test.starcultural.com";
// 测试环境
const testUrl = "https://qingyu-api-test.starcultural.com";
// const testUrl = 'https://qingyu-ios-test.kemeba.com';//ios
// 正式环境
const prodUrl = "https://qingyu-api.starcultural.com";

if (process.env.NODE_ENV == "development") {
  baseUrl = devUrl;
} else {
  if (process.env.VUE_APP_FLAG === "pro") {
    baseUrl = prodUrl;
  } else if (process.env.VUE_APP_FLAG === "test") {
    baseUrl = testUrl;
  } else {
    baseUrl = devUrl;
  }
}

export default {
  baseUrl,
};
