var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.show,
      expression: "show"
    }],
    staticClass: "cy-loading"
  }, [_c('div', {
    staticClass: "center-container"
  }, [_vm.$slots.default ? _vm._t("default") : _c('div', [_vm._v("......")])], 2)]);
};
var staticRenderFns = [];
export { render, staticRenderFns };