/**插槽
 * cellL：左边内容
 * rank：排行榜位置
 * avatar：头像
 * user：用户信息
 * cellR：右边内容
 */
export default {
  name: "Cell",
  props: {
    rank: {
      default: ""
    },
    //这三个数组都是只有1-2个元素作为页面渲染
    avatar: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    user: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    right: {
      type: Array,
      default: function _default() {
        return [];
      }
    }
  },
  data: function data() {
    return {};
  }
};