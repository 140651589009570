export const festivalList = [
  {
    path: "/limitTimeTopUp",
    name: "limitTimeTopUp",
    meta: {
      name: "限时充值",
    },
    component: () => import("../pages/limitTimeTopUp"),
  },
  {
    path: "/suggest",
    name: "suggest",
    meta: {
      name: "倡议书",
    },
    component: () => import("../pages/suggest"),
  },
  {
    path: "/topUpRebate",
    name: "topUpRebate",
    meta: {
      name: "充值返利",
    },
    component: () => import("../pages/topUpRebate"),
  },
  // {
  //   path: "/festival20240101",
  //   name: "festival20240101",
  //   meta: {
  //     name: "2024-01-01",
  //   },
  //   component: () => import("../pages/festival20240101"),
  // },
  {
    path: "/treasureBox",
    name: "treasureBox",
    meta: {
      name: "开宝箱活动",
    },
    component: () => import("../pages/treasureBox"),
  },
  {
    path: "/newYear2024",
    name: "newYear2024",
    meta: {
      name: "春节活动",
    },
    component: () => import("../pages/newYear2024"),
  },
  {
    path: "/redBagRain",
    name: "redBagRain",
    meta: {
      name: "红包雨活动",
    },
    component: () => import("../pages/redBagRain"),
  },
];
