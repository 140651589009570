export default {
  name: "festivalBtm",
  props: {
    userInfo: {
      type: Object,
      default: function _default() {
        return null;
      }
    },
    noUserInfo: {
      type: Object,
      default: function _default() {
        return {
          text: "",
          btnText: ""
        };
      }
    },
    showUser: {
      default: true
    } //外部传值控制user数据展示
  },

  computed: {
    getUserInfo: function getUserInfo() {
      if (this.userInfo) {
        return this.userInfo;
      } else {
        return {
          rankNum: 0,
          avatarL: "",
          avatarR: "",
          userL: "",
          userR: "",
          rightTitle: "",
          score: ""
        };
      }
    }
  }
};