import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import './utils/time'

import "@/config/axios";
import "@/components/index.js";
import "@/assets/css/index.scss";
import "@/plugins/vant.js";
import Fragment from "vue-fragment";
Vue.use(Fragment.Plugin);
Vue.config.productionTip = false;
//300毫秒
import fastClick from "fastclick";
fastClick.attach(document.body);
//解决fastClick插件引起的ios键盘问题（输入框卡顿，失焦，弹不出软键盘）
fastClick.prototype.focus = function (targetElement) {
  var length;
  var deviceIsWindowsPhone = navigator.userAgent.indexOf("Windows Phone") >= 0;
  var deviceIsIOS =
    /iP(ad|hone|od)/.test(navigator.userAgent) && !deviceIsWindowsPhone;
  //兼容处理:在iOS7中，有一些元素（如date、datetime、month等）在setSelectionRange会出现TypeError
  //这是因为这些元素并没有selectionStart和selectionEnd的整型数字属性，所以一旦引用就会报错，因此排除这些属性才使用setSelectionRange方法
  if (
    deviceIsIOS &&
    targetElement.setSelectionRange &&
    targetElement.type.indexOf("date") !== 0 &&
    targetElement.type !== "time" &&
    targetElement.type !== "month" &&
    targetElement.type !== "email"
  ) {
    length = targetElement.value.length;
    targetElement.setSelectionRange(length, length); //修复bug ios 11.3不弹出键盘，这里加上聚焦代码，让其强制聚焦弹出键盘
    targetElement.focus();
  } else {
    targetElement.focus();
  }
};
import dayjs from "dayjs";
//加载中文语言包
// import 'dayjs/locale/zh-cn'
// import relativeTime from 'dayjs/plugin/relativeTime'
// // 全局使用中文包
// dayjs.locale('zh-cn')
// // 相对时间配置
// dayjs.extend(relativeTime)
//全局函数
Vue.prototype.timeFmt = (val, fmtString) => {
  fmtString = fmtString || "YYYY-MM-DD HH:mm:ss";
  val = val * 1000;
  return dayjs(val).format(fmtString);
};

//过滤器
//月日时分
Vue.filter("timeMDHS", (value) => {
  return dayjs(value * 1000).format("MM-DD HH:mm");
});
//年月日
Vue.filter("timeYMD", (value) => {
  return dayjs(value * 1000).format("YYYY-MM-DD");
});
//时分秒
Vue.filter("timeHS", function (value) {
  if (value <= 0) return "00:00:00";
  // 返回处理后的值
  value *= 1000;
  var h, m, s;
  if (value >= 0) {
    h = Math.floor((value / 1000 / 60 / 60) % 24);
    m = Math.floor((value / 1000 / 60) % 60);
    s = Math.floor((value / 1000) % 60);
  }
  if (h < 10) {
    h = "0" + h;
  }
  if (m < 10) {
    m = "0" + m;
  }
  if (s < 10) {
    s = "0" + s;
  }
  return `${h}:${m}:${s}`;
});
//今天或者年月日
Vue.filter("timeYD", function (value) {
  let time = value - new Date(new Date().toLocaleDateString()).getTime() / 1000;
  if (time > 0) {
    return "今天";
  }
  return dayjs(value * 1000).format("YYYY-MM-DD");
});
//处理number(逢3逗号)
Vue.filter("returnNum", function (value) {
  value += "";
  //判断有没有小数
  if (value.indexOf(".") != -1) {
    var tempArr = value.split(".");
    value = tempArr[0];
    tempArr = tempArr[1];
    value = value.split("");
  } else {
    value = value.split("");
  }
  value.reverse();
  //这一步之前必须是数组
  let l = value.length;
  for (let index = 1; index < 5; index++) {
    let n = 3 * index;
    if (l > n) {
      value.splice(n + index - 1, 0, ",");
    } else {
      break;
    }
  }
  value.reverse();
  if (tempArr) {
    return value.join("") + "." + tempArr;
  }
  return value.join("");
});
//处理夺宝页面单元格标签
Vue.filter("labelContext", function (value) {
  let prizeType = {
    1: "礼物",
    2: "座驾",
    3: "气泡",
    4: "头像框",
    5: "钻石",
  };
  value += "";
  return prizeType[value];
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");

//杂物
Vue.prototype.myToast = () => {
  Vue.prototype.$toast.loading({
    message: "加载中...",
    forbidClick: true,
    overlay: true,
    duration: 0,
  });
};
//隐藏中间四位id
Vue.prototype.hideId = (value) => {
  value = value + "";
  let arr = value.split("");
  let len = Math.floor(arr.length / 2);
  if (len - 2 <= 0 || len + 2 > arr.length) return false;
  arr[len - 1] = "*";
  arr[len - 2] = "*";
  arr[len] = "*";
  arr[len + 1] = "*";
  return arr.join("");
};

//解决tab切换scrollTop跳动问题，tab切换后调用此方法(注意调用位置在切换后)
Vue.prototype.resetScroll = function () {
  const scrollTop = document.body.scrollTop;
  this.$nextTick(() => {
    document.body.scrollTop = scrollTop;
  });
}


//app转发服务端消息(调用h5方法)
window.apptransitMessage = function (data) {
  console.log('app传进的参数:', data);
  console.log("[Receive ApptransitMessage] => ", data, "; Data type => ", typeof data);
  if (typeof data === "string") {
    try {
      data = JSON.parse(data);
    } catch (error) { console.log(error); }
  }
  try {
    const { noticeCode } = data;
    (window.apptransitMessage.handlerMap[noticeCode] || []).forEach(fun => {
      try { fun(data) } catch (e) { console.log(e); }
    });
  } catch (error) { console.log(error); }
}
window.apptransitMessage.handlerMap = {};
window.apptransitMessage.register = function (code, handler) {
  console.log("[ApptransitMessageHanlder Register] => success!");
  window.apptransitMessage.handlerMap[code] || (window.apptransitMessage.handlerMap[code] = []);
  window.apptransitMessage.handlerMap[code].indexOf(handler) !== -1 || window.apptransitMessage.handlerMap[code].push(handler);
}
