//夺宝抽奖数据
const snatch = {
    namespaced: true,
    state: {
        key: 0,//钥匙
        gem: 0,//宝石
        keyCount: '',//抽奖一次消耗的钥匙
        drawResultObj: {
            num: 0,
            arr: []
        },//抽奖结果对象，包含抽几次，和抽奖结果数组
        drawLock: false,//抽奖事件锁，true为开启
        againDraw: false,//继续抽奖,变动即继续，无关true和false
        upspring: {
            ruleUp: false,//规则弹窗
            recordUp: false,//记录弹窗
            conversionUp: false,//兑换弹窗
            drawResult: false//抽奖结果弹窗
        }
    },
    mutations: {
        setKey(state, value) {
            state.key = value
        },
        setGem(state, value) {
            state.gem = value
        },
        setKeyCount(state, value) {
            state.keyCount = value
        },
        setResultArr(state, value) {
            state.drawResultObj = value
        },
        setDrawLock(state, value) {
            state.drawLock = value
        },
        setAgainDraw(state, value) {
            state.againDraw = value
        },
        //弹窗修改处
        setRuleUp(state, value) {
            state.upspring.ruleUp = value
        },
        setRecord(state, value) {
            state.upspring.recordUp = value
        },
        setConversion(state, value) {
            state.upspring.conversionUp = value
        },
        setResult(state, value) {
            state.upspring.drawResult = value
        },


    },
    actions: {
        // increment(context, num) {
        //     context.commit('setGem', num)
        // }
    },
}
export default snatch